import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faArrowUp,
    faBook,
    faBriefcaseMedical,
    faBrowser,
    faCar,
    faCalendar,
    faCheckSquare,
    faCircleDollar,
    faCircleInfo,
    faCogs,
    faEye,
    faFaceZipper,
    faFileAlt,
    faFileCheck,
    faGifts,
    faGlobe,
    faHandHeart,
    faHandHoldingHeart,
    faHandshake,
    faHandshakeAngle,
    faHashtag,
    faHeartbeat,
    faIslandTropical,
    faLifeRing,
    faLightbulb,
    faLock,
    faLockKeyhole,
    faPeopleArrowsLeftRight,
    faPeopleGroup,
    faPersonShelter,
    faSackDollar,
    faSquareCheck,
    faSearch,
    faSnake,
    faSparkles,
    faSquareUser,
    faStar,
    faSun,
    faTooth,
    faTreePalm,
    faUmbrella,
    faUser,
    faUserCog,
    faUserGroup,
    faUsers,
    faUsersCog,
    faUsersMedical,
    faUserPlus,
    faWheelchair,
} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

library.add(
    faArrowUp,
    faBook,
    faBriefcaseMedical,
    faBrowser,
    faCar,
    faCalendar,
    faCheckSquare,
    faCircleDollar,
    faCircleInfo,
    faCogs,
    faEye,
    faFaceZipper,
    faFileAlt,
    faFileCheck,
    faGifts,
    faGlobe,
    faHandHeart,
    faHandHoldingHeart,
    faHandshake,
    faHandshakeAngle,
    faHashtag,
    faHeartbeat,
    faIslandTropical,
    faLifeRing,
    faLightbulb,
    faLock,
    faLockKeyhole,
    faPeopleArrowsLeftRight,
    faPeopleGroup,
    faPersonShelter,
    faSackDollar,
    faSearch,
    faSnake,
    faSparkles,
    faSquareCheck,
    faSquareUser,
    faStar,
    faSun,
    faTooth,
    faTreePalm,
    faUmbrella,
    faUser,
    faUserCog,
    faUserGroup,
    faUsers,
    faUsersCog,
    faUsersMedical,
    faUserPlus,
    faWheelchair,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
