import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {extend} from 'vee-validate';
import {excluded, max, max_value, min_value, numeric, regex, required} from 'vee-validate/dist/rules'; // eslint-disable-line camelcase
import Vue from 'vue';

extend('excluded', excluded);
extend('max', max);
extend('max_value', max_value);
extend('min_value', min_value);
extend('numeric', numeric);
extend('regex', regex);
extend('required', {
    ...required,
    message: 'This field is required'
});

extend('uniq', (value, [uniqFieldName, uniqValue]) => {
    return value === uniqValue ? `This field and ${uniqFieldName} must be unique` : true;
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
