<template>
    <footer
        data-cy="intranetFooter"
    >
        <p class="footer-header">
            IMT Vision: Provide peace of mind in the moments that&nbsp;matter.
        </p>
        <p class="footer-header">
            The values that hold us true&nbsp;everyday.
        </p>
        <div class="values-row">
            <div
                class="values-container"
                data-cy="valuesContainer"
            >
                <div class="header-row">
                    <div class="icon-container-lg">
                        <FontAwesomeIcon
                            :icon="['far', 'shield-check']"
                        />
                    </div>
                    <p class="header-text">
                        Trust
                    </p>
                </div>
                <p class="values-text">
                    We believe in integrity, honesty and reliability across IMT Insurance. Our model
                    of trust is based on character and competencies that demonstrate we listen first, keep commitments,
                    and extend trust.
                </p>
            </div>
            <div
                class="values-container"
                data-cy="valuesContainer"
            >
                <div class="header-row">
                    <div class="icon-container-lg">
                        <FontAwesomeIcon
                            :icon="['far', 'hands-heart']"
                        />
                    </div>
                    <p class="header-text">
                        Relationships
                    </p>
                </div>
                <p class="values-text">
                    We understand the importance of partnerships. The foundation of IMT Insurance is high-touch customer
                    service. We are there for our partners and employees when they need us; people helping people.
                </p>
            </div>
            <div
                class="values-container"
                data-cy="valuesContainer"
            >
                <div class="header-row">
                    <div class="icon-container-lg">
                        <FontAwesomeIcon
                            :icon="['far', 'handshake-alt']"
                        />
                    </div>
                    <p class="header-text">
                        Collaboration
                    </p>
                </div>
                <p class="values-text">
                    We work together utilizing the collective talents of everyone at IMT Insurance to meet our
                    customers’ needs and achieve success. A collaborative spirit of teamwork built upon valued
                    relationships and loyalty.
                </p>
            </div>
            <div
                class="values-container"
                data-cy="valuesContainer"
            >
                <div class="header-row">
                    <div class="icon-container-lg">
                        <FontAwesomeIcon
                            :icon="['far', 'rocket']"
                        />
                    </div>
                    <p class="header-text">
                        Innovation
                    </p>
                </div>
                <p class="values-text">
                    We listen to customers and employees. New ideas are explored and put into action to make us
                    easy-to-do business with – for improved customer service, knowledgeable agents, and effective
                    employees.
                </p>
            </div>
            <div
                class="values-container"
                data-cy="valuesContainer"
            >
                <div class="header-row">
                    <div class="icon-container-lg">
                        <FontAwesomeIcon
                            :icon="['far', 'medal']"
                        />
                    </div>
                    <p class="header-text">
                        Professional Development
                    </p>
                </div>
                <p class="values-text">
                    We prepare our team members to serve our customers and to be successful. Resources are provided to
                    continually develop the skills and knowledge of the IMT Insurance staff and agency force.
                </p>
            </div>
            <div
                class="values-container"
                data-cy="valuesContainer"
            >
                <div class="header-row">
                    <div class="icon-container-lg">
                        <FontAwesomeIcon
                            :icon="['far', 'users']"
                        />
                    </div>
                    <p class="header-text">
                        Engagement
                    </p>
                </div>
                <p class="values-text">
                    We believe in a work environment that is fun, flexible and fair. We value and care about our
                    employees. As a company, we support our communities both financially and through volunteerism.
                </p>
            </div>
        </div>
        <div class="footer-footer">
            <a
                href="https://www.imtins.com/"
                title="IMT Insurance external website"
            >
                <img
                    class="footer-logo"
                    :src="whiteIMTLogo"
                    alt="IMT Insurance Logo"
                    data-cy="footerLogo"
                >
            </a>
            <div class="whistleblower">
                <a
                    target="_blank"
                    href="https://forms.office.com/r/WWZXrcE4dj"
                    title="Employee whistleblower form"
                >
                    <FontAwesomeIcon
                        :icon="['far', 'lock']"
                    />
                    Whistleblower
                </a>
            </div>
            <div class="social-icons">
                <p class="social-icons-header">
                    Follow us
                </p>
                <div class="social-icons-row">
                    <a
                        href="https://www.linkedin.com/company/imt-insurance"
                        title="IMT Insurance on LinkedIn"
                    >
                        <div class="icon-container-sm">
                            <FontAwesomeIcon
                                :icon="['fab', 'linkedin-in']"
                                data-cy="socialIcon"
                            />
                        </div>
                    </a>
                    <a
                        href="https://www.facebook.com/imtinsurance"
                        title="IMT Insurance on Facebook"
                    >
                        <div class="icon-container-sm">
                            <FontAwesomeIcon
                                :icon="['fab', 'facebook-f']"
                                data-cy="socialIcon"
                            />
                        </div>
                    </a>
                    <a
                        href="https://www.youtube.com/user/BeWorryFree"
                        title="IMT Insurance on YouTube"
                    >
                        <div class="icon-container-sm">
                            <FontAwesomeIcon
                                :icon="['fab', 'youtube']"
                                data-cy="socialIcon"
                            />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import {library} from '@fortawesome/fontawesome-svg-core';
    import {
        faFacebookF,
        faLinkedinIn,
        faYoutube,
    } from '@fortawesome/free-brands-svg-icons';
    import {
        faHandshakeAlt,
        faHandsHeart,
        faMedal,
        faRocket,
        faShieldCheck,
        faUsers,
    } from '@fortawesome/pro-regular-svg-icons';

    library.add(
        faFacebookF,
        faHandshakeAlt,
        faHandsHeart,
        faLinkedinIn,
        faMedal,
        faRocket,
        faShieldCheck,
        faUsers,
        faYoutube,
    );

    export default {
        name: 'IFooter',
        computed: {
            whiteIMTLogo() {
                /* istanbul ignore next */
                return process.env.VUE_APP_WHITE_IMT_LOGO;
            },
        }
    };
</script>

<style
    lang="sass"
    scoped
>

    footer
        min-height: 5rem
        background-color: var(--kc-blue-600)
        color: white
        padding: 1rem 0 2rem 0
        position: static
        bottom: 0
        left: 0
        right: 0

        @media (min-width: 576px)
            padding: 3rem 0 1rem 0

        .footer-header
            text-align: left
            font-size: 1.25rem
            margin: 0 0 1.5rem 0.5rem
            line-height: 1.25

            @media (min-width: 576px)
                margin: 0 3rem 2rem 3rem
                line-height: 2

        .values-row
            display: flex
            flex-direction: row
            flex-wrap: wrap
            justify-content: flex-start
            padding: 0 0 0 1rem
            max-width: 100vw

            @media (min-width: 576px)
                justify-content: space-evenly
                padding-left: 3rem

            .values-container
                display: flex
                flex-direction: column
                //max-width: 15rem
                min-width: 5%
                margin: 0 2rem 2rem 0

                @media (min-width: 576px)
                    max-width: 15rem

                @media (min-width: 1135px) and (max-width: 1681px)
                    min-width: 25%

                .header-row
                    display: flex

                    .header-text
                        margin: 0
                        font-size: 1.2rem
                        text-align: left

                    .icon-container-lg
                        min-width: 3.5rem
                        min-height: 3.5rem
                        max-width: 3.5rem
                        max-height: 3.5rem
                        background-color: white
                        border-radius: 0.5rem
                        display: flex
                        align-items: center
                        justify-content: center
                        margin-right: 1rem

                        svg
                            color: var(--kc-blue-500)
                            font-size: 1.8rem

                .values-text
                    text-align: left
                    width: 100%
                    font-size: 0.9rem
                    margin-top: 1rem
                    @media (min-width: 576px)
                        margin-top: 2rem

        .footer-footer
            display: flex
            flex-direction: column
            align-items: center
            max-width: 100vw
            padding: 0 3rem 0 3rem

            @media (min-width: 576px)
                flex-direction: row
                justify-content: space-between

            .footer-logo
                height: 6rem
                margin-bottom: 1rem

                @media (min-width: 576px)
                    margin-bottom: 0

            .whistleblower
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                padding-bottom: 1rem

                @media (min-width: 576px)
                    padding-bottom: 0

                a
                    color: white
                    text-decoration: none

                    &:hover
                        text-decoration: underline

            .social-icons
                text-align: center

                @media (min-width: 576px)
                    text-align: left

                .social-icons-header
                    margin-bottom: 0.25rem

                .social-icons-row
                    display: flex
                    flex-direction: row
                    justify-content: space-between

                    .icon-container-sm
                        min-width: 2rem
                        min-height: 2rem
                        max-width: 2rem
                        max-height: 2rem
                        background-color: white
                        border-radius: 0.5rem
                        display: flex
                        align-items: center
                        justify-content: space-around
                        margin: 0 0.5rem 0 0.5rem

                        @media (min-width: 576px)
                            margin: 0 1rem 0 0

                        svg
                            color: var(--kc-blue-500)
                            font-size: 1.2rem

</style>
