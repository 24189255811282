import toolbox from '@imt/vue-toolbox/src/store';
import utils, {axiosWithAuth} from '@imt/vue-toolbox/src/utils';
import dayjs from 'dayjs';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const authedAxios = axiosWithAuth();

authedAxios.interceptors.response.use((response) => {
    return response;
}, function(error) {
    if (error.request.status === 403 && !window.Cypress) {
        window.location = `${process.env.VUE_APP_ADMIN_URL_USERS}?next=${encodeURIComponent(window.location.href)}`;
    }

    return Promise.reject(error);
});

export const actions = {
    async fetchAllArticles(context, {query = null, dateRange = [], offset = null, featured = null}) {
        let querystring = '';

        querystring += query ? `&search=${query}` : '';
        querystring += dateRange && dateRange.length ? `&filter[first_published_at.gte]=${dateRange[0]}T00:00:00&filter[first_published_at.lte]=${dateRange[1]}T23:59:59` : '';
        querystring += offset ? `&offset=${offset}` : '';
        querystring += featured ? '&featured=true' : '';

        const response = await authedAxios.get(`/articles/${querystring ? `?${querystring}` : ''}`);

        context.commit('SET_ALL_ARTICLES_PAGING', {meta: response.data.meta.pagination});

        return utils.dataFormatter.deserialize(response.data.data);
    },
    async fetchIMTHappenings(context, {
        query = null,
        dateRange = [],
        page = null,
        category = null,
        subcategory = null
    }) {
        let querystring = '';

        querystring += query ? `search=${query}&` : '';
        querystring += dateRange && dateRange.length ? `filter[first_published_at.gte]=${dateRange[0]}T00:00:00&filter[first_published_at.lte]=${dateRange[1]}T23:59:59&` : '';
        querystring += page ? `page=${page}&` : '';
        querystring += category ? `category=${category}&` : '';
        querystring += subcategory ? `subcategory=${subcategory}&` : '';

        const response = await authedAxios.get(`/imt-happenings/${querystring ? `?${querystring}` : ''}`);

        context.commit('SET_IMT_HAPPENINGS_PAGING', {meta: response.data.meta.pagination});

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchDepartmentNews(context, {
        query = null,
        dateRange = [],
        page = null
    }) {
        let querystring = '';

        querystring += query ? `search=${query}&` : '';
        querystring += dateRange && dateRange.length ? `filter[first_published_at.gte]=${dateRange[0]}T00:00:00&filter[first_published_at.lte]=${dateRange[1]}T23:59:59&` : '';
        querystring += page ? `page=${page}&` : '';

        const response = await authedAxios.get(`/department-news/${querystring ? `?${querystring}` : ''}`);

        context.commit('SET_DEPT_NEWS_PAGING', {meta: response.data.meta.pagination});

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchDepartmentNewsArticle(context, {articleSlug, preview = false}) {
        const response = await authedAxios.get(`/department-news/${articleSlug}/${preview ? '?preview=true' : ''}`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchIMTHappening(context, {articleSlug, preview = false}) {
        const response = await authedAxios.get(`/imt-happenings/${articleSlug}/${preview ? '?preview=true' : ''}`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchHRUpdate(context, {articleSlug, preview = false}) {
        const response = await authedAxios.get(`/hr-updates/${articleSlug}/${preview ? '?preview=true' : ''}`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchHRUpdates(context, {query = null, dateRange = [], page = null}) {
        let querystring = '';

        querystring += query ? `&search=${query}` : '';
        querystring += dateRange && dateRange.length ? `&filter[first_published_at.gte]=${dateRange[0]}T00:00:00&filter[first_published_at.lte]=${dateRange[1]}T23:59:59` : '';
        querystring += page ? `&page=${page}` : '';

        const response = await authedAxios.get(`/hr-updates/${querystring ? `?${querystring}` : ''}`);

        context.commit('SET_HR_UPDATES_PAGING', {meta: response.data.meta.pagination});

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchEmployeeAnnouncement(context, {articleSlug, preview = false}) {
        const response = await authedAxios.get(`/employee-announcements/${articleSlug}/${preview ? '?preview=true' : ''}`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchEmployeeAnnouncements(context, {query = null, dateRange = [], page = null}) {
        let querystring = '';

        querystring += query ? `&search=${query}` : '';
        querystring += dateRange && dateRange.length ? `&filter[first_published_at.gte]=${dateRange[0]}T00:00:00&filter[first_published_at.lte]=${dateRange[1]}T23:59:59` : '';
        querystring += page ? `&page=${page}` : '';

        const response = await authedAxios.get(`/employee-announcements/${querystring ? `?${querystring}` : ''}`);

        context.commit('SET_EMPLOYEE_ANNOUNCEMENTS_PAGING', {meta: response.data.meta.pagination});

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchCelebrations(context, {startDate = null}) {
        let querystring = startDate ? `start_date=${startDate}` : '';

        const response = await authedAxios.get(`/celebrations/${querystring ? `?${querystring}` : ''}`);

        return utils.dataFormatter.deserialize(response.data.data);
    },
    async fetchEvents(context, {startDate = null, preview = null, subcategory = null}) {
        let querystring = preview ? `preview=${preview}&` : '';
        querystring += startDate ? `filter[date.gte]=${startDate}&` : '';
        querystring += subcategory ? `subcategory=${subcategory}` : '';

        const response = await authedAxios.get(`/events/${querystring ? `?${querystring}` : ''}`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchAvailableFavorites() {
        const linkResponse = await authedAxios.get('/links/?available_favorite=True&page[size]=1000');

        return utils.dataFormatter.deserialize(linkResponse.data);
    },
    async fetchFavorites(context, {querystring = ''}) {
        const response = await authedAxios.get(`/favorites/${querystring ? `?${querystring}` : ''}`);

        let responseData = utils.dataFormatter.deserialize(response.data);

        responseData.forEach((favorite) => {
            favorite.link = mapKeys(favorite.link, (value, key) => camelCase(key));
        });

        if (response.data.meta.pagination.pages > response.data.meta.pagination.page) {
            responseData.push(...await actions.fetchFavorites({}, {querystring: `${querystring ? querystring + '&' : ''}page=${response.data.meta.pagination.page + 1}`}));
        }

        return responseData;
    },
    async createFavorite(context, {favorite}) {
        const response = await authedAxios.post('/favorites/', {
            data: {
                type: 'Favorite',
                attributes: {
                    link: {
                        id: favorite.link.id,
                        type: 'Link',
                        ...utils.dataFormatter.serialize({stuff: favorite.link}).data.attributes
                    }
                }
            }
        });

        let responseData = utils.dataFormatter.deserialize(response.data);

        responseData.link = mapKeys(responseData.link, (value, key) => camelCase(key));

        return responseData;
    },
    async updateFavorite(context, favorite) {
        const response = await authedAxios.patch(`/favorites/${favorite.id}/`,
            utils.dataFormatter.serialize({stuff: {type: 'Favorite', ...favorite}})
        );

        return utils.dataFormatter.deserialize(response.data);
    },
    async deleteFavorite(context, id) {
        return await authedAxios.delete(`/favorites/${id}/`);
    },
    async fetchLinks(context, {location, section}) {
        let querystring = `location=${location}`;

        querystring += section ? `&section=${section}` : '';

        const linkResponse = await authedAxios.get(`/links/?page[size]=1000${querystring ? `&${querystring}` : ''}`);

        return utils.dataFormatter.deserialize(linkResponse.data);
    },
    async fetchPage(context, {pageSlug, preview = false}) {
        const response = await authedAxios.get(`/general-pages/${pageSlug}/${preview ? '?preview=true' : ''}`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchNotifications(context) {
        const response = await authedAxios.get(`/notifications/`);

        context.commit('SET_NOTIFICATIONS', response.data.data);
    },
    async fetchClassifieds() {
        const response = await authedAxios.get(`/classifieds/`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchEmployees(context, {search = '', department = '', page = null, query = null}) {
        const querystring = `${search ? `search=${search}&` : ''}${department && department !== 'all' ? `filter[department]=${department}&` : ''}${page ? `page=${page}&` : ''}${query ? `${query}&` : ''}`,
            response = await authedAxios.get(`/profiles/${querystring ? `?${querystring}` : ''}`);

        context.commit('SET_EMPLOYEE_DIRECTORY_PAGING', {meta: response.data.meta.pagination});

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchEmployee(context, {userID = null, email = null, paylocityID = null}) {
        if (paylocityID) {
            const response = await authedAxios.get(`/profiles/${paylocityID}/?include=reports_to`);

            return utils.dataFormatter.deserialize(response.data);
        } else if (email) {
            const response = await authedAxios.get(`/profiles/?filter[email]=${email}&include=reports_to`);

            return utils.dataFormatter.deserialize(response.data)[0];
        } else if (userID) {
            const response = await authedAxios.get(`/profiles/?filter[users_id]=${userID}&include=reports_to`);

            return utils.dataFormatter.deserialize(response.data)[0];
        }
    },
    async saveEmployee(context, {paylocityID, patchData}) {
        const response = await authedAxios.patch(`/profiles/${paylocityID}/`, utils.dataFormatter.serialize({
            stuff: {
                type: 'Profile',
                id: paylocityID,
                usersId: context.state.toolbox.user.id,
                ...patchData
            }
        }));

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchSearchResults(context, {query = '', dateRange = [], limit = 101, offset = null}) {
        let querystring = query ? `search=${query}&` : '',
            responseData = {};
        querystring += dateRange && dateRange.length ? `daterange[start]=${dateRange[0]}&daterange[end]=${dateRange[1]}&` : '';
        querystring += limit ? `limit=${limit}` : '';
        querystring += offset ? `offset=${offset}` : '';

        const response = await authedAxios.get(`/search/?${querystring}`);

        for (let key in response.data.data.data) {
            responseData[key] = response.data.data.data[key].length ? utils.dataFormatter.deserialize({data: response.data.data.data[key]}) : [];
        }

        context.commit('SET_SEARCH_OFFSET', {offset: response.data.meta.offset});

        return responseData;
    },
};

export const mutations = {
    SET_ALL_ARTICLES_PAGING(state, {meta}) {
        state.AllArticlesPages = meta.pages;
        state.AllArticlesCount = meta.count;
    },
    SET_IMT_HAPPENINGS_PAGING(state, {meta}) {
        state.IMTHappeningsPages = meta.pages;
        state.IMTHappeningsCount = meta.count;
    },
    SET_DEPT_NEWS_PAGING(state, {meta}) {
        state.DeptNewsPages = meta.pages;
    },
    SET_HR_UPDATES_PAGING(state, {meta}) {
        state.HRUpdatesPages = meta.pages;
        state.HRUpdatesCount = meta.count;
    },
    SET_EMPLOYEE_DIRECTORY_PAGING(state, {meta}) {
        state.EmployeeDirectoryPages = meta.pages;
        state.EmployeeDirectoryCount = meta.count;
    },
    SET_EMPLOYEE_ANNOUNCEMENTS_PAGING(state, {meta}) {
        state.EmployeeAnnouncementsPages = meta.pages;
        state.EmployeeAnnouncementsCount = meta.count;
    },
    SET_SEARCH_OFFSET(state, {offset}) {
        state.SearchOffset = offset;
    },
    SET_NOTIFICATIONS(state, notifications) {
        state.notifications = notifications;
        state.notificationsLastFetched = dayjs();
    },
    CLEAR_NOTIFICATION_INDICATOR(state, value) {
        state.notifications[value] = false;
    }
};

export const state = () => {
    return {
        notificationsLastFetched: null,
        notifications: {},
        categories: {
            business_updates: 'Business Updates',
            cultural_roadmap: 'Cultural Roadmap',
            news_and_events: 'News & Events',
        },
        calendarCategories: {
            all: {
                name: 'All',
            },
            company_events: {
                name: 'Company Events',
                categories: [
                    'community',
                    'facilities',
                    'general',
                    'hr',
                ],
            },
            employee_celebrations: {
                name: 'Employee Celebrations',
                categories: [
                    'general',
                ],
            },
            cultural_roadmap: {
                name: 'Cultural Roadmap',
                categories: [
                    'leadership_advocacy',
                    'cultural_roadmap_metrics',
                    'innovation',
                    'customer_experience',
                    'corporate_communication',
                    'dei',
                    'onboarding',
                    'continuing_education',
                    'safety',
                ],
            },
        },
        departments: {
            accounting: 'Accounting',
            bonds: 'Bonds',
            claims: 'Claims',
            commercial_lines_underwriting: 'Commercial Lines Underwriting',
            dei: 'DEI',
            executive_division: 'Executive Division',
            human_resources: 'Human Resources',
            information_systems: 'Information Systems',
            internal_audit: 'Internal Audit',
            network_services: 'Network Services',
            research_and_development: 'Research and Development',
            loss_control: 'Loss Control',
            mail_center: 'Mail Center',
            marketing_and_sales: 'Marketing and Sales',
            personal_lines_underwriting: 'Personal Lines Underwriting',
            premium_audit: 'Premium Audit',
            project_management_office: 'Project Management Office',
            software_services: 'Software Services',
            technical_services: 'Technical Services',
            eden: 'Eden',
        },
        departmentOptions: [
            {
                'text': 'All',
                'value': 'all'
            },
            {
                'text': 'Accounting',
                'value': 'accounting'
            },
            {
                'text': 'Bonds',
                'value': 'bonds'
            },
            {
                'text': 'Claims',
                'value': 'claims'
            },
            {
                'text': 'Commercial Lines Underwriting',
                'value': 'commercial_lines_underwriting'
            },
            {
                'text': 'DEI',
                'value': 'dei'
            },
            {
                'text': 'Executive Division',
                'value': 'executive_division'
            },
            {
                'text': 'Human Resources',
                'value': 'human_resources'
            },
            {
                'text': 'Information Systems',
                'value': 'information_systems'
            },
            {
                'text': 'Internal Audit',
                'value': 'internal_audit'
            },
            {
                'text': 'Loss Control',
                'value': 'loss_control'
            },
            {
                'text': 'Mail Center',
                'value': 'mail_center'
            },
            {
                'text': 'Marketing and Sales',
                'value': 'marketing_and_sales'
            },
            {
                'text': 'Network Services',
                'value': 'network_services'
            },
            {
                'text': 'Personal Lines Underwriting',
                'value': 'personal_lines_underwriting'
            },
            {
                'text': 'Premium Audit',
                'value': 'premium_audit'
            },
            {
                'text': 'Project Management Office',
                'value': 'project_management_office'
            },
            {
                'text': 'Research and Development',
                'value': 'research_and_development'
            },
            {
                'text': 'Software Services',
                'value': 'software_services'
            },
            {
                'text': 'Technical Services',
                'value': 'technical_services'
            },
            {
                'text': 'Eden',
                'value': 'eden'
            },
        ],
        HRCategories: {
            all: 'All',
            business_updates: 'Business Updates',
            news_and_events: 'News & Events',
        },
        subcategories: {
            ceo_connect: 'CEO Connect',
            financial_updates: 'Financial Updates',
            goal_updates: 'Goal Updates',
            software_services: 'IMT Software Services',
            industry_updates: 'Industry Updates',
            pmo_updates: 'PMO Updates',
            human_resources: 'Human Resources',
            general_celebration: 'General',

            community: 'Community',
            education: 'Education',
            facilities: 'Facilities',
            general: 'General',

            care_council: 'Care Council',
            continuing_education: 'Continuing Education',
            core_values: 'Core Values',
            core_values_council: 'Core Values',
            crew: 'CREW',
            customer_service: 'Customer Service',
            customer_experience_and_service: 'Customer Experience and Service',
            dei_collective: 'DEI Collective',
            diversity_equity_inclusion: 'Diversity, Equity & Inclusion',
            employee_experience_and_appreciation: 'Employee Experience & Appreciation',
            engagement_survey: 'Engagement Survey',
            imt_connect: 'IMT Connect',
            imt_mentorship: 'IMT Mentorship',
            imt_university: 'IMT University - Learning Pathways',
            innovation: 'Innovation',
            innovation_council: 'Innovation Council',
            leadership_development: 'Leadership Development',
            leadership_playbook: 'Leadership Playbook',
            live_balanced: 'Live Balanced',
            recognition_and_appreciation: 'Recognition & Appreciation'
        },
        sections: {
            // I know these aren't in alphabetical order, but these orders were specifically requested
            cultural_roadmap: {
                strategic_alignment: 'Strategic Alignment',
                organizational_development: 'Organizational Development',
                leadership_transformation: 'Leadership Transformation',
                performance_improvement: 'Performance Improvement',
                employee_engagement: 'Employee Engagement',
            },
            tools_and_resources: {
                company: 'Company',
                time_and_pay: 'Time & Pay',
                benefits: 'Benefits',
                policy_docs: 'Policies & Documents',
                learning_pathways: 'Learning Pathways',
            },
            my_workspace: {
                tools: 'Tools',
                accounting_resources: 'Accounting Resources',
                marketing_resources: 'Marketing Resources',
                help_desk_docs: 'Help Desk Docs',
                reports_other: 'Reports/Other',
            },
            documents_and_resources: {
                comm_policy_docs: 'Commercial Lines',
                personal_policy_docs: 'Personal Lines',
                billing_docs: 'Billing',
            }
        },
        menuItems: {
            'imt-happenings': new Map([
                ['Company Calendar', {
                    title: 'Company Calendar',
                    to: {name: 'imt-happenings.calendar'},
                }],
                ['IMT Blog', {
                    link: 'https://theimtblog.com/',
                    target: '_blank',
                    title: 'IMT Blog',
                }]
            ]),
            'cultural-roadmap': new Map([
                ['Programs', {
                    children: new Map([
                        ['Overview', {
                            name: 'Overview',
                            to: {name: 'cultural-roadmap'},
                        }],
                        ['Core Values', {
                            name: 'Core Values',
                            to: {name: 'cultural-roadmap.page', params: {pageSlug: 'core-values'}},
                        }],
                        ['Customer Experience & Service', {
                            name: 'Customer Experience & Service',
                            to: {name: 'cultural-roadmap.page', params: {pageSlug: 'customer-experience-service'}},
                        }],
                        ['Diversity, Equity, & Inclusion', {
                            name: 'Diversity, Equity, & Inclusion',
                            to: {name: 'cultural-roadmap.page', params: {pageSlug: 'diversity-equity-inclusion'}},
                        }],
                        ['Employee Experience & Appreciation', {
                            name: 'Employee Experience & Appreciation',
                            to: {name: 'cultural-roadmap.page', params: {pageSlug: 'employee-experience-appreciation'}},
                        }],
                        ['Innovation', {
                            name: 'Innovation',
                            to: {name: 'cultural-roadmap.page', params: {pageSlug: 'innovation'}},
                        }],
                        ['Leadership & Development', {
                            name: 'Leadership & Development',
                            to: {name: 'cultural-roadmap.page', params: {pageSlug: 'leadership-development'}},
                        }],
                        ['Living Strategy', {
                            name: 'Living Strategy',
                            to: {name: 'cultural-roadmap.page', params: {pageSlug: 'living-strategy'}},
                        }],
                    ]),
                }],
                ['Committees & Councils', {
                    children: new Map([
                        ['Innovation Council', {
                            name: 'Innovation Council',
                            to: {name: 'cultural-roadmap.page', params: {pageSlug: 'innovation-council'}},
                        }],
                        ['Care Council', {
                            name: 'Care Council',
                            to: {name: 'cultural-roadmap.page', params: {pageSlug: 'care-council'}},
                        }],
                        ['Customer Service', {
                            name: 'Customer Service',
                            to: {name: 'cultural-roadmap.page', params: {pageSlug: 'customer-service'}},
                        }],
                        ['CREW', {
                            name: 'CREW',
                            to: {name: 'cultural-roadmap.page', params: {pageSlug: 'crew'}},
                        }],
                        ['Live Balanced', {
                            name: 'Live Balanced',
                            to: {name: 'cultural-roadmap.page', params: {pageSlug: 'live-balanced'}},
                        }],
                    ]),
                }],
                ['About IMT', {
                    children: new Map([
                        ['Senior Leadership', {
                            title: 'Senior Leadership',
                            target: '_blank',
                            link: 'https://www.imtins.com/about/senior-leadership.php',
                        }],
                        ['Leadership Team', {
                            title: 'Leadership Team',
                            target: '_blank',
                            link: 'https://www.imtins.com/about/leadership.php',
                        }],
                        ['Board of Directors', {
                            title: 'Board of Directors',
                            target: '_blank',
                            link: 'https://www.imtins.com/about/board.php',
                        }],
                        ['Mission, Vision, Values', {
                            title: 'Mission, Vision, Values',
                            target: '_blank',
                            link: 'https://www.imtins.com/about/mission.php',
                        }],
                        ['Annual Report', {
                            title: 'Annual Report',
                            target: '_blank',
                            link: 'https://www.imtins.com/about/report.php',
                        }],
                        ['History', {
                            title: 'History',
                            target: '_blank',
                            link: 'https://www.imtins.com/about/history.php',
                        }],
                    ]),

                }

                ]
            ]),
        },
        AllArticlesCount: null,
        AllArticlesPages: null,
        DeptNewsPages: null,
        EmployeeAnnouncementsCount: null,
        EmployeeAnnouncementsPages: null,
        HRUpdatesCount: null,
        HRUpdatesPages: null,
        IMTHappeningsCount: null,
        IMTHappeningsPages: null,
        EmployeeDirectoryPages: null,
        EmployeeDirectoryCount: null,
        SearchOffset: null
    };
};

export default new Vuex.Store({
    modules: {
        toolbox,
    },
    actions,
    mutations,
    state: state(),
});
