import '@/plugins/vee-validate';
import '@/plugins/font-awesome';
import toastsMixin from '@imt/vue-kit-car/src/mixins/toasts';
import BootstrapVue from 'bootstrap-vue';
import Vue from 'vue';
import VueGtag from 'vue-gtag';
import VueRouter from 'vue-router';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

Vue.config.productionTip = false;
Vue.mixin(toastsMixin);
Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(VueGtag, {
    config: {
        id: process.env.VUE_APP_ANALYTICS_ID,
        params: {
            cookie_flags: "SameSite=None;Secure",
        },
        pageTrackerUseFullPath: true,
    }
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
