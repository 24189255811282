<template>
    <BOverlay
        id="overlay-background"
        spinner-type="none"
        :show="overlay.show"
        :variant="overlay.variant"
        :opacity="overlay.opacity"
        :blur="overlay.blur"
    >
        <MobileMenu
            data-cy="mobileMenuWithItems"
            @sidebar-change="handleSidebarChange"
        >
            <div class="navigation-search mobile">
                <BInputGroupPrepend class="search-icon">
                    <FontAwesomeIcon
                        :icon="['far', 'search']"
                    />
                </BInputGroupPrepend>

                <BFormInput
                    v-model="searchQuery"
                    data-cy="navSearchInput"
                    placeholder="Search..."
                    @keydown.enter="performSearch"
                />

                <KCButton
                    data-cy="navigationSearchButton"
                    :disabled="!searchQuery"
                    variant="secondary"
                    @click="performSearch"
                >
                    Search
                </KCButton>
            </div>
            <MenuItem
                :active="$route.name === 'home'"
                :to="{name: 'home'}"
                data-cy="mobileMenuItem"
            >
                Home
            </MenuItem>
            <MenuItem
                v-for="item in headerNavigation"
                :key="item.title"
                :active="item.isActive"
                :disabled="item.disabled"
                :open="item.isActive"
                :to="{name: item.name}"
                data-cy="mobileMenuItem"
            >
                {{ item.title }}
                <template
                    v-if="menuItems[item.name]"
                    #dropdown-items
                >
                    <MenuItem
                        data-cy="mobileMenuItem"
                        target="_self"
                        :to="{name: item.name}"
                    >
                        Overview
                    </MenuItem>
                    <MenuItem
                        v-for="[itemTitle, menuItem] in menuItems[item.name]"
                        :key="menuItem.order"
                        data-cy="mobileMenuItem"
                        :target="menuItem.target || '_self'"
                        :to="menuItem.to"
                        :href="menuItem.link"
                    >
                        {{ itemTitle }}
                        <template
                            v-if="menuItem.children"
                            #dropdown-items
                        >
                            <MenuDropdownItem
                                v-for="[childTitle, childItem] in menuItem.children"
                                :key="childItem.order"
                                data-cy="mobileMenuItem"
                                :target="childItem.target || '_self'"
                                :to="childItem.to"
                                :href="childItem.link"
                            >
                                {{ childTitle }}
                            </MenuDropdownItem>
                        </template>
                    </MenuItem>
                </template>
            </MenuItem>
        </MobileMenu>
        <!-- Desktop Navigation -->
        <KCHeader
            data-cy="headerWithNavItems"
            :user="user"
            :variant="header.variant"
            :elevated="header.elevated"
            @logout="handleLogout"
            @toggle-mobile-menu="handleToggleMobileMenu"
            @reset-password="handleResetPassword"
        >
            <template #branding>
                <RouterLink :to="{name: 'home'}">
                    <IMTLogo
                        data-cy="headerBranding"
                        class="imt-logo"
                    />
                </RouterLink>
            </template>
            <div class="nav-row">
                <BNav>
                    <BNavItem
                        v-for="item in headerNavigation"
                        :key="item.title"
                        :active="item.isActive"
                        data-cy="mainNavItem"
                        :class="{'nav-item-badge': item.indicator}"
                        :to="{name: item.name}"
                    >
                        {{ item.title }}
                    </BNavItem>
                </BNav>
                <div class="navigation-search desktop">
                    <BInputGroupPrepend class="search-icon">
                        <FontAwesomeIcon
                            :icon="['far', 'search']"
                        />
                    </BInputGroupPrepend>

                    <BFormGroup :class="[{'has-query': searchQuery}]">
                        <BFormInput
                            v-model="searchQuery"
                            data-cy="navSearchInput"
                            placeholder="Search..."
                            @keydown.enter="performSearch"
                        />
                        <div class="search-button-container">
                            <KCButton
                                variant="secondary"
                                data-cy="navigationSearchButton"
                                @click="performSearch"
                            >
                                Search
                            </KCButton>
                        </div>
                    </BFormGroup>
                </div>
            </div>
            <template #user-dropdown-header>
                <FontAwesomeIcon
                    class="icon"
                    :icon="['far', 'user']"
                />

                <div>
                    <p class="title">
                        {{ user.firstName }} {{ user.lastName }}
                    </p>
                    <p class="subtitle">
                        {{ user.jobTitle }}
                    </p>
                </div>
            </template>
            <template #user-dropdown-items>
                <BDropdownDivider />

                <UserDropdownItem
                    v-if="userHasIntranetAdminAccess"
                    :href="cmsURL"
                    data-cy="cmsLink"
                >
                    <FontAwesomeIcon
                        class="icon"
                        :icon="['far', 'cogs']"
                    />
                    CMS Admin
                </UserDropdownItem>
                <UserDropdownItem
                    :to="{name: 'people-and-culture.employee-directory.my-profile'}"
                    class="d-none d-md-block"
                    data-cy="preferencesLink"
                    @click="closeDropdown()"
                >
                    <FontAwesomeIcon
                        class="icon"
                        :icon="['far', 'user-cog']"
                    />
                    Preferences
                </UserDropdownItem>
                <UserDropdownItem
                    :to="{name: 'people-and-culture.employee-directory.my-profile.favorites'}"
                    class="d-none d-md-block"
                    data-cy="favoritesLink"
                    @click="closeDropdown()"
                >
                    <FontAwesomeIcon
                        class="icon"
                        :icon="['far', 'star']"
                    />
                    Favorites
                </UserDropdownItem>

                <BDropdownDivider />
            </template>
        </KCHeader>
        <div
            class="page-container"
        >
            <transition
                name="slide-side-nav"
                mode="out-in"
            >
                <KCMenu
                    v-if="hasSideMenu"
                    data-cy="menuWithItems"
                >
                    <MenuItem
                        v-for="[itemTitle, menuItem] in menuItems[$route.matched[0].name]"
                        :key="menuItem.order"
                        :active="menuItem.to == $route.path"
                        data-cy="menuItem"
                        :target="menuItem.target"
                        :to="menuItem.to"
                        :href="menuItem.link"
                    >
                        {{ itemTitle }}
                        <template
                            v-if="menuItem.children"
                            #dropdown-items
                        >
                            <MenuDropdownItem
                                v-for="[childTitle, childItem] in menuItem.children"
                                :key="childItem.order"
                                data-cy="menuItem"
                                :target="childItem.target || '_self'"
                                :to="childItem.to"
                                :href="childItem.link"
                            >
                                {{ childTitle }}
                            </MenuDropdownItem>
                        </template>
                    </MenuItem>
                </KCMenu>
            </transition>

            <slot
                name="content"
            />
        </div>
        <IntranetFooter />
    </BOverlay>
</template>

<script>
    import KCButton from '@imt/vue-kit-car/src/components/Button.vue';
    import IMTLogo from '@imt/vue-kit-car/src/components/IMTLogo.vue';
    import KCHeader from '@imt/vue-kit-car/src/components/layout/Header.vue';
    import KCMenu from '@imt/vue-kit-car/src/components/layout/Menu.vue';
    import MenuDropdownItem from '@imt/vue-kit-car/src/components/layout/MenuDropdownItem.vue';
    import MenuItem from '@imt/vue-kit-car/src/components/layout/MenuItem.vue';
    import MobileMenu from '@imt/vue-kit-car/src/components/layout/MobileMenu.vue';
    import UserDropdownItem from '@imt/vue-kit-car/src/components/layout/UserDropdownItem.vue';
    import dayjs from 'dayjs';
    import {mapActions, mapState, mapMutations} from 'vuex';

    import IntranetFooter from '@/components/IFooter.vue';

    export default {
        name: 'INavigation',
        components: {
            KCButton,
            KCHeader,
            IMTLogo,
            IntranetFooter,
            KCMenu,
            MenuDropdownItem,
            MenuItem,
            MobileMenu,
            UserDropdownItem,
        },
        data() {
            return {
                header: {
                    elevated: false,
                    variant: 'default',
                },
                headerNavigation: [
                    {
                        title: 'Workspace',
                        name: 'workspace',
                    },
                    {
                        title: 'IMT Happenings',
                        name: 'imt-happenings',
                    },
                    {
                        title: 'Human Resources',
                        name: 'human-resources',
                    },
                    {
                        title: 'Cultural Roadmap',
                        name: 'cultural-roadmap',
                    },
                ],
                searchQuery: null,
                overlay: {
                    show: false,
                    variant: 'transparent',
                    opacity: 0.85,
                    blur: '0',
                },
            };
        },
        computed: {
            cmsURL() {
                return process.env.VUE_APP_CMS_URL;
            },
            intranetPermissions() {
                return Object.entries(this.permissions).filter(permission => permission[0].includes('intranet'));
            },
            userHasIntranetAdminAccess() {
                return this.intranetPermissions.find(permission => (permission[0].includes('admin') || permission[0].includes('read_only')) && permission[1]);
            },
            hasSideMenu() {
                return !!this.menuItems[this.$route.matched[0]?.name];
            },
            ...mapState([
                'notifications',
                'notificationsLastFetched',
                'menuItems',
            ]),
            ...mapState('toolbox', [
                'user',
                'permissions',
            ]),
        },
        watch: {
            $route() {
                this.loadActiveStatus();
                this.loadNotifications();
            }
        },
        async mounted() {
            await this.fetchUserIfNoPermissions();
            this.loadActiveStatus();
            await this.loadNotifications();
            window.addEventListener('focus', () => {
                this.checkNotificationsLastFetched();
            });
        },
        methods: {
            performSearch() {
                this.$router.push({name: 'search', query: {search: this.searchQuery}});
                this.searchQuery = null;
            },
            loadActiveStatus() {
                this.headerNavigation.forEach((item, index) => {
                    this.$set(this.headerNavigation[index], 'isActive', !!this.$route.matched.some(({name}) => name === item.name));
                });
            },
            checkNotificationsLastFetched() {
                if (dayjs().diff(dayjs(this.notificationsLastFetched), 'm') > 15) {
                    this.loadNotifications();
                }
            },
            async loadNotifications(newRoute = this.$route) {
                await this.fetchNotifications();
                this.headerNavigation.forEach((item, index) => {
                    for (const notification in this.notifications) {
                        if (newRoute?.name?.indexOf(notification) > -1 && this.notifications[notification]) {
                            this.CLEAR_NOTIFICATION_INDICATOR(notification);
                        }
                    }

                    this.$set(this.headerNavigation[index], 'indicator', this.notifications[item.name] || false);
                });
            },
            async fetchUserIfNoPermissions() {
                if (!Object.keys(this.permissions).length) {
                    await this.fetchUser();
                }
            },
            handleToggleMobileMenu() {
                this.$root.$emit('bv::toggle::collapse', 'mobile-menu');
            },
            closeDropdown() {
                this.$root.$emit('bv::hide::popover');
            },
            handleSidebarChange() {
                this.overlay.show = !this.overlay.show;
            },
            handleLogout() {
                /* istanbul ignore next */
                window.location = `${process.env.VUE_APP_ADMIN_URL_USERS}/logout`;
            },
            handleResetPassword() {
                /* istanbul ignore next */
                window.location = `${process.env.VUE_APP_ADMIN_URL_USERS}/change-password`;
            },
            ...mapActions('toolbox', [
                'fetchUser',
            ]),
            ...mapActions(['fetchNotifications']),
            ...mapMutations(['CLEAR_NOTIFICATION_INDICATOR'])
        },
    };
</script>

<style
    lang="sass"
>
    .imt-logo
        height: 5rem

    .page-container
        display: flex
        flex-direction: row
        width: 100%

    button.menu-link,
    .menu-item a.menu-link
        height: 3.75rem

    button.menu-link.active
        font-weight: bold !important

    a.menu-link
        padding: 0 0.25rem 0 0
        height: 2rem
        font-weight: normal !important

    div.menu-dropdown div.menu-item-container div.menu-item a.menu-link
        padding: 0 0.25rem 0

    div.menu-dropdown div.menu-item-container div.menu-item
        padding-left: 1.25rem

        &.open
            height: auto

    div.menu-item-container div.menu-item-container
        border-top: none !important
        border-bottom: none !important

    div.menu-item-container div.menu-item
        display: flex
        flex-direction: column

    button.menu-link.active
        font-weight: bold !important

    .d-flex.flex-grow-1.align-items-center.h-100
        .header-content.d-flex.align-items-center
            width: 100% !important
            @media (min-width: 1200px)
                z-index: 999

            div.nav-row
                display: flex
                flex-direction: row
                justify-content: space-between
                align-items: center
                width: 100%
                flex-wrap: nowrap

                ul.nav
                    display: none

                    @media(min-width: 576px)
                        display: flex
                        justify-content: flex-start
                        flex-wrap: nowrap

    div.menu-item button
        font-weight: normal !important

    div.menu-item.open
        padding-bottom: 0 !important

    div.menu-item.open,
    div.menu-item:hover
        border-left-color: #bbbfc4 !important

    div.menu-item.active
        border-left-color: #4e81bf !important

    div.menu-dropdown
       padding-left: .5rem !important

    div.menu-dropdown div.menu-item a.menu-link
        padding-left: 0 !important

    div.menu-dropdown div.menu-item a.menu-link.active
        color: #4e81bf
        font-weight: normal !important
        text-decoration: underline

    div.menu-dropdown div.menu-item
        border-left: none !important

    a.menu-dropdown-item
        color: #707d8e !important

    .menu-item .menu-link
        padding: 0 1rem !important

    .menu-dropdown .menu-item-container
        padding: 0

    .navigation-search
        position: relative
        margin: 0 1rem

        .search-icon
            position: absolute
            top: 0.75rem
            left: 0.75rem
            z-index: 100
            color: var(--kc-gray-600)
            font-size: 1rem

        input.form-control
            padding-left: 2.25rem !important
            margin: 0

        &.desktop
            .form-group, .form-group > div
                display: flex
                flex-direction: row
                flex-wrap: nowrap
                justify-content: flex-end
                height: 2.625rem
                max-height: 2.625rem
                min-width: min-content

                input
                    max-height: unset
                    height: 100%
                    transition: border-radius 800ms ease-in-out

                .search-button-container
                    height: 100%
                    width: 0
                    overflow: hidden
                    transition: width 800ms ease-in-out

                @media (min-width: 1300px)
                    &.has-query
                        input
                            border-radius: 3px 0 0 3px

                        .search-button-container
                            width: 12rem

                            .btn
                                border-radius: 0 3px 3px 0

            @media (max-width: 1200px)
                display: none

        &.mobile
            max-width: 100%
            display: flex
            flex-direction: column
            flex-wrap: nowrap
            justify-content: flex-end
            align-items: center
            margin-bottom: 3rem

            input.form-control
                height: 2rem

            .btn
                margin-top: 1rem

    .menu.desktop-menu
        position: relative
        flex-shrink: 0
        flex-grow: 0
        @media (min-width: 1200px)
            width: 19.05rem

        &.slide-side-nav-enter-active,
        &.slide-side-nav-leave-active
            transition: width 0.25s ease-out
            min-width: unset
            overflow-x: hidden

            .menu-body
                overflow-x: hidden
                width: 19rem

        &.slide-side-nav-enter
            min-width: unset
            width: 0

        &.slide-side-nav-enter-to
            width: 19rem

        &.slide-side-nav-leave-to
            width: 0

    .popover-body
        .user-dropdown-item:nth-last-child(2)
            display: none!important

    .menu-dropdown .menu-item-container .menu-item .menu-link
        color: #707d8e

    .menu-dropdown .menu-item-container .menu-item .menu-link .icon
        rotate: 180deg
        transition: transform .3s

    .menu-dropdown .menu-item-container .menu-item.open .menu-link .icon
        rotate: 0deg
        transition: transform .3s

    .menu-dropdown .menu-item-container .menu-item .menu-link
      height: 2.5rem

    aside.menu .menu-dropdown-item:first-child
      padding-top: .5rem

    aside.menu .menu-dropdown-item:last-child
      padding-bottom: 1rem
</style>
