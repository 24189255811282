<template>
    <div
        v-if="permissionsHaveLoaded && userHasAccess && userHasProfile"
        id="app"
    >
        <Navigation>
            <template #content>
                <main class="content">
                    <transition
                        name="fade"
                        mode="out-in"
                    >
                        <RouterView />
                    </transition>
                </main>
            </template>
        </Navigation>
        <BackToTop
            top-element="app"
        />
    </div>
    <div
        v-else-if="!permissionsHaveLoaded || !employeeVerified"
        class="app-loading-indicator"
    />
    <Error
        v-else-if="employeeVerified && !employee"
        icon="lock-alt"
        title="You are not fully configured to use the Intranet"
        subtitle="This page and content is locked"
    >
        <slot>
            <p>
                Please contact <a href="mailto:humanresources@imtins.com">humanresources@imtins.com</a>
                to check your Paylocity settings.
            </p>
        </slot>
        <template #actions>
            <hr>
        </template>
    </Error>
    <ErrorForbidden
        v-else
    />
</template>

<script>
    import BackToTop from '@imt/vue-kit-car/src/components/BackToTop.vue';
    import Error from '@imt/vue-kit-car/src/components/Error.vue';
    import ErrorForbidden from '@imt/vue-kit-car/src/components/errors/Forbidden.vue';
    import auth from '@imt/vue-toolbox/src/mixins/auth';
    import utils from '@imt/vue-toolbox/src/utils';
    import {mapActions, mapState} from 'vuex';

    import Navigation from '@/components/INavigation.vue';

    export default {
        name: 'App',
        components: {
            BackToTop,
            ErrorForbidden,
            Error,
            Navigation,
        },
        mixins: [auth],
        data() {
            return {
                employee: null,
                employeeVerified: false
            };
        },
        computed: {
            permissionsHaveLoaded() {
                return !!this.user.id;
            },
            userHasAccess() {
                return !!this.permissions.intranet_access;
            },
            userHasProfile() {
                return !!this.employee;
            },
            ...mapState('toolbox', [
                'user',
                'permissions',
            ]),
            ...mapState([
                'notificationsLastFetched',
            ]),
        },
        watch: {
            async user() {
                await this.checkForUserProfile();
            }
        },
        methods: {
            async checkForUserProfile() {
                try {
                    this.employee = await this.fetchEmployee({
                        userID: this.user.id,
                    });
                } catch (err) /* istanbul ignore next */ {
                    utils.console.log(err);
                } finally {
                    this.employeeVerified = true;
                }
            },
            ...mapActions(['fetchEmployee'])
        },
    };
</script>

<style lang="sass">
    @import '~@imt/vue-kit-car/src/sass/kit-car'
    @import '~@/sass/tabs.sass'

    body
        background-color: var(--kc-blue-200)
        color: var(--kc-blue-800) !important
        margin: 0
        padding: 0
        max-width: 100vw
        min-height: 100vh

        h1
            margin-bottom: 2rem

        .block
            display: block

        .grid
            display: grid

        .app-loading-indicator
            position: absolute
            top: 0
            right: 0
            left: 0
            bottom: 0
            border-top: 0.375rem solid var(--kc-red-500)
            margin: 0 !important
            background-color: white

        .page-view
            min-height: calc(100vh - (8.62rem + 31.25rem))
            display: flex
            flex-direction: column
            width: 100%

            .page-view
                display: block

            .page
                padding: 2rem
                width: 100%
                max-width: 100vw

        .content
            padding: 1rem 2rem 2rem 2rem
            width: 100%
            min-height: calc(100vh - (8.62rem + 31.25rem))

            @media(max-width: 768px)
            padding: 2rem

    a.navigation-link
        color: var(--kc-text)
        text-decoration: none

        &:hover
            cursor: pointer
            color: var(--kc-blue-500) !important
            text-decoration: underline

        &:visited
            color: var(--kc-text)

    .error-view svg
        margin-top: 3rem

    // Route Transitions
    .fade-enter-active,
    .fade-leave-active
        transition-duration: 0.25s
        transition-property: opacity
        transition-timing-function: ease

    .fade-enter,
    .fade-leave-active
        opacity: 0
</style>
